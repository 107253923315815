export const colors = {
  black: "#000000",
  white: "#FFFFFF",
  neutral: "#D1CAC2",
  text: {
    primary: "#1F1F1E",
    secondary: "#938E88",
    tertiary: "#C6C6C6",
    disabled: "#C8C8C8",
    icon: "#4D4A47",
  },
  neutrals: {
    borders: "#D3D1CF",
    text: {
      tertiary: "#C6C6C6",
      primary: "#1F1F1E",
      secondary: "#938E88",
      disabled: "#C8C8C8",
      icon: "#4D4A47",
    },
  },

  accents: {
    navy: {
      primary: "#153B60",
    },
  },

  shadows: {
    main: "rgba(0,0,0,0.25)",
  },

  brand: {
    red: {
      500: "#AE2223",
    },
    primary: "#CAE0E8",
    secondary: "#E6F7FF",
    hover: "#A9C8D3",
    turquoise: "#38A1A8",
    pink: "#FDDCDA",
    sale: "#EA2223",
    border: "#CECECE",
    divider: "#E9E9E9",
    nude: "#d8c3b1",
    khaki: "#787e69",
    beige: {
      dark: "#F3F2EE",
      light: "#F8F7F5",
    },
    navy: {
      dark: "#012446",
      light: "#153B60",
    },
  },
  system: {
    success: {
      dark: "#357235",
      light: "#479847",
    },
    error: {
      dark: "#C01617",
      light: "#fef6f5",
    },
    warning: "#FFCC6A",
    focus: "#0E8CD2",
  },
  gradients: {
    bronze: "linear-gradient(to right, #E0BE81, #C38C2D)",
    silver: "linear-gradient(to right, #D5D9DB, #A9AAAE)",
    gold: "linear-gradient(to right, #ECCF5A, #AC8D30)",
  },
}
