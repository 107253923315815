import { useMemo } from "react"
import { ShopifyProduct } from "@usereactify/search/dist/types/shopify"
import { BadgeProps } from "@chakra-ui/react"

export type ProductBadge = {
  label: string
  props: BadgeProps
}

// Dictionary containing mappings from tag themes to colour schemes
const BadgePropMap: Record<string, BadgeProps> = {
  red: { bg: "brand.sale", color: "white" },
  blue: { bg: "brand.primary" },
  navy: { bg: "brand.navy.dark", color: "white" },
  green: { bg: "system.success.dark", color: "white" },
  pink: { bg: "brand.pink" },
  turquoise: { bg: "brand.turquoise", color: "white" },
  grey: { bg: "text.secondary", color: "white" },
  neutral: { bg: "neutral" },
  transparent: { bg: "none" },
  nude: { bg: "brand.nude", color: "black" },
  khaki: { bg: "brand.khaki", color: "white" },
}

/**
 * Parses product label tags using the format label:<name>:<color>.
 *
 * Returns necessary properties to render each badge colour scheme, otherwise
 * defaulting to primary blue branding.
 *
 * @param tags list of tags sourced from Shopify product
 */
const useProductBadges = (tags: ShopifyProduct["tags"]) => {
  return useMemo(() => {
    return tags?.reduce((acc: ProductBadge[], tag: string) => {
      const [prefix, label, theme] = tag.split(":")

      if (prefix.toLowerCase() === "label") {
        // If colour scheme is not found, default to blue scheme
        const props = BadgePropMap[theme?.toLowerCase() || "blue"] || BadgePropMap.blue

        // Convert label to title case, as CSS rules are not flexible enough to handle all cases when capitalizing
        const titleCaseLabel = label.replace(/\w\S*/g, text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase())

        acc.push({ label: titleCaseLabel, props })
      }

      return acc
    }, [])
  }, [tags])
}

export default useProductBadges
